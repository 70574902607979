import { withRouter,useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { addToCart, editFromCart, removeFromCart } from "../redux/cartSlice";
import _ from "lodash";
import { calculateKuver, calculateOrderPrice, deleteAlert, getHostName, parsePrice, scaleImage, somethingWentWrong, successMessage } from "../provider/functions";
import { useEffect } from "react";
import { callFunction } from "../provider/firebase";
import { ProductLoadSuccess } from "../redux";
import BottomNavigation from "../components/BottomNavigation";
export default withRouter(CartPage);
function CartPage({ history }) {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  const isWebStore = match.params.table=="webstore" || match.params.table=="online";
  const serverReducer = useSelector((state) => state.server.data);
  const {currency,bgcolor1,kuver} = serverReducer;
  const [loading,setloading] = useState(false)
  const language = useSelector((state) => state.language.data);
  const cart = useSelector((state) => state.cart.data);
  const toplam = parseFloat( parseFloat( cart.map(v=>calculateOrderPrice(v)).reduce((a, b) => a + b, 0)).toFixed(2))
  const {freekargo,kargo,min,open,isClosed} = serverReducer.webstore || {open:false,min:0,kargo:0,freekargo:0,isClosed:false}
  const kargoFiyati = parsePrice(isWebStore ? toplam>freekargo ? 0 : kargo : 0);
  const kuverUcreti =( !isWebStore && kuver) ?calculateKuver(kuver,cart) :0;
  function nextPage(route){
    setloading(true)
    const stokluOlanlar = cart.map((v,k)=>({...v,cartIndex:k})).filter(v=>v.product && v.product.stokluSatis)
    const qts = Object.values(_.groupBy(stokluOlanlar,v=>v.product.id)).map(values=>({...values[0],cartIndex:values.map(v=>v.cartIndex),quantity:values.map(v=>parseInt(v.quantity)).reduce((a,b)=>a+b,0)}))
    if(stokluOlanlar.length>0){
      window.grecaptcha.enterprise.ready(()=> window.grecaptcha.enterprise.execute('6Lc7XZMqAAAAAOxV4_eE7mic2A4fDg_BxowGYlzR', {action: 'qrMenu'}).then(token=>{
        return callFunction("stokKontrol")({
        token,
        server: match.params.server ? match.params.server : getHostName(),
      }).then(({data})=>{
        var silinenler = [];
        const products = data.products
        var newCart = _.cloneDeep(cart);
        dispatch(ProductLoadSuccess(products.sort((a,b)=>a.rank-b.rank)));
       for (let index = 0; index < qts.length; index++) {
          const element = qts[index];
          const find = products.find(v=>v.id==element.product.id)
          if(!find){
            silinenler.push(element)
            element.cartIndex.map(v=>dispatch(removeFromCart(v)))
            continue;
          }
          const stok = find.stok - element.quantity;
        
          if(stok<0){
            silinenler.push(element)
            element.cartIndex.map(v=>dispatch(editFromCart({
              index:v,
              quantity: parseInt(newCart[v].quantity)+parseInt(stok),
              product:element.product
            })))

          }
        }
        if(silinenler.length>0){
          successMessage(language.product_stokta_yok,language.product_stokta_yok_aciklama+" : "+silinenler.map(v=>v.product.title).join(),"error")
        }else{
          return history.push(route)
        }
      }).catch(err=>{
        somethingWentWrong("Error","stock check error");
      })
      .finally(()=>setloading(false))
      }))
      return false;
    }
    setloading(false)
     history.push(route)
    
  }
  function plus(index,artis=1){
    const newQt = parseInt(cart[index].quantity) + artis;
    if(cart[index].product.stokluSatis && newQt>cart[index].product.stok){
      dispatch(
        editFromCart({
          index,
          quantity: cart[index].product.stok,
        })
      );
      successMessage(language.product_urun_stokta_yok_yada_az,language.product_urun_stokta_yok_yada_az_aciklama+" : "+cart[index].product.stok,"warning")
    }else{
      dispatch(
        editFromCart({
          index,
          quantity: newQt,
        })
      );
    }
  }
  function changeNote(index,text){
      dispatch(
        editFromCart({
          index,
          note: text,
        })
      );
  }
  return (
    <div>
      <div className="rp-app rp-search">
        <div className="container-fluid">
        <div className="rp-page-head rp-head-shop">
            {/* <a onClick={()=>history.goBack()} className="back" style={{zIndex:999}}><i className="fa fa-arrow-left" /></a> */}
            <h1 className="title title-cart">{language.sepet} <span className="shop-name">{serverReducer.title}</span></h1>
          </div>
          <div className="rp-cart-steps active2"> 
            <div className="step">
              <span className="number" >1</span>
              <span className="name"  style={{color:serverReducer.textColor}}>{language.menu}</span>
            </div>
            <div className="step">
              <span className="number">2</span>
              <span className="name"  style={{color:serverReducer.textColor}}>{language.sepet}</span>
            </div>
            <div className="step">
              <span className="number">3</span>
              <span className="name"  style={{color:serverReducer.textColor}}>{language.odeme || "Payment"}</span>
            </div>
          </div>
          
          {cart.map(({product,quantity,options,note}, index) => ( <div className="rp-cart-item2" key={index}>
            <div className="number-input">
              <button  onClick={() => { plus(index,-1)}} />
              <input className="quantity"   min={1}  name="quantity" value={quantity} max={product.stokluSatis ? product.stok:Infinity} disabled type="number" required />
              <button  onClick={() => {
                            plus(index,1)
                          }} className="plus" />
            </div>
            <div className="content">
              <div className="info" style={{width:"100%"}}>
                <div className="img">
                   {product.image &&<img src={scaleImage(product.image)} alt="Product" />}
                  </div>
                <div className="info-content" style={{width:"100%"}}>
                  <span className="item-name"  style={{color:serverReducer.textColor}}>{product.qrTitle || product.title}

                  <span className="item-price" style={{color:serverReducer.textColor,float:'right'}}>{calculateOrderPrice({product,options},quantity)} {currency}</span>
                  </span>
              
                  <span className="item-desc"  style={{color:"serverReducer.textColor"}}>
          
                  </span>
                  {options.map(v=>v.data ?? []).reduce((a,b)=>a.concat(b),[]).filter((v) => v.checked).map(o=><span className="item-desc"  style={{color:"serverReducer.textColor"}}>
                  {o.title}
                  </span>)} 
                  <input className="w100 " style={{padding:0,border:'none',background:'transparent',color:'#6c757d'}} value={note} onChange={e=>changeNote(index,e.target.value)} />
                </div>
              </div>
            </div>
          </div>))}
          <div style={{height:200}}></div>
          <div className="rp-action-margin-cart"></div>
         

       
  
      </div>
      <div className="rp-action rp-action-cart" style={{bottom:70}}>
      {(kuverUcreti)  ?  <div className="price-info" >
          <span className="text"> <small className="text-muted">{language.ayarlar_kuver}</small></span>
          <span className="price"><small className="text-muted">+{kuverUcreti} {currency}</small></span>
        </div>:""}
      {(kargoFiyati)  ?  <div className="price-info" >
          <span className="text"> <small className="text-muted">{language.teslimat_ucreti}</small></span>
          <span className="price"><small className="text-muted">+{kargoFiyati} {currency}</small></span>
        </div>:""}
        <div className="price-info" style={{marginBottom:0}}>
          <span className="text">{language.toplam}</span>
          <span className="price">{toplam+kargoFiyati+kuverUcreti} {currency}</span>
        </div>
      
    
        <button  onClick={() =>{
            if(toplam<min && isWebStore){
              return somethingWentWrong(language.minimum_tutar_altinda_baslik,language.minimum_tutar_altinda_aciklama.replace("${min}",min).replace("${currency}",currency))
            }else if(isClosed && isWebStore){
              return somethingWentWrong(language.webstore_kapali_baslik,language.webstore_kapali_aciklama)
            }else if(isWebStore || serverReducer.auth){
              return nextPage((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/login/")
            }
            nextPage((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/paycart/")
          }}
          style={{marginTop:10}}
          disabled={cart.length==0 || loading} className="btn btn-dark btn-block" >  {language.siparis_ver}</button>
      </div>

   
      </div>
      <BottomNavigation/>
    </div>
  );
}
{/* <div className="rp-action rp-action-cart">
<div className="table">
  <table className="table">
    <tbody>
     {kargoFiyati>0 && <>
      <tr>
        <td>{language.siparisler}</td>
        <td>{toplam} {currency}</td>
      </tr>
      <tr>
        <td>{language.teslimat_ucreti}</td>
        <td>{kargoFiyati} {currency}</td>
      </tr>
      </>}
      <tr>
        <td>{language.toplam}</td>
        <td>
          {toplam+kargoFiyati} {currency}
        </td>
      </tr>
    </tbody>
  </table>
</div>

</div> */}